
h2 
{
  text-align:center;
}

.login-logo {
  text-align:right;
  font-size: larger;
  padding-top: 3px;
  color:grey;
}

h3.home-product-title{
  padding-left: 20px;
  padding-bottom: 15px;
  color: red;
  font-size: x-large;
}

@media (max-width: 767px) {

  .login-logo {
    display: none;
  }

}

