.accepted-format-img {
  margin-left: 13px;
  width: 30px;
  height: 30px;
}

.download-image {
  width: 30px;
}

.specification-table-header {
  color: white;
  background-color: #fa0f34;
}
