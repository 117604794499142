.utech-header {
  font-size: 12px;
}

.header-cart {
  display: unset;
  cursor: pointer;
}

.signup-group {
  margin-left: 50px;
}

.auth-links {
  display: inline-block;
  position: relative;
  right: 50px;
  top: 30px;
}

.loadingModal {
  top: calc((100vh - 180px)/2);
  transform: translateY(-calc((100vh - 180px)/2));
  opacity: 1;
  background: rgba(0,0,0,0);
}

.loadingModal .modal-content {
  background: rgba(0,0,0,0.0);
  border: none;
}

.loginButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin-top: 15px;
  margin-bottom: 15px;
}

.closeIcon {
  font-size: 36px;
  position: absolute;
  right: 15px;
}

.btn {
  margin-left: 4px;
  margin-right: 4px;
}

#login_email {
  text-transform: none;
}

#login_password {
  text-transform: none;
}

#forgotPassword {
  text-align: center;
  margin-bottom: 10px;
}

.facebookBtn:hover {
  opacity: 0.5;
}

.button.login {
  background: rgba(35,33,33, 1.0);
  color: white;
  padding: 10px 10px;
  margin: 5px;
  width: 120px;
}

.button.login:hover {
  background: rgba(135,133,133, 1.0);
  padding: 10px 10px;
  margin: 5px;
  width: 120px;
}

.googleBtn:hover {
  opacity: 0.5;
}

.facebookBtn {
  outline: none;
  text-align: center;
  background: url(./overlay.png) repeat-x center #3b5998;
  border: 0px;
  font-family: Arial, Helvetica, sans-serif;
  color: rgba(255,255,255,1.00);
  letter-spacing: 1px;
  text-shadow: 0;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  padding:13px 20px;
  margin: 10px;
  width: 250px;

  -webkit-box-shadow: 8px 8px 8px -5px rgba(0,0,0,0.20);
  -moz-box-shadow: 8px 8px 8px -5px rgba(0,0,0,0.20);
  box-shadow: 8px 8px 8px -5px rgba(0,0,0,0.20);
  box-sizing: border-box;
}

.googleBtn {
  outline: none;
  text-align: center;
  background: url(./overlay.png) repeat-x center #D34836;
  border: 0px;
  font-family: Arial, Helvetica, sans-serif;
  color: rgba(255,255,255,1.00);
  letter-spacing: 1px;
  text-shadow: 0;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  padding:13px 20px;
  margin: 10px;
  width: 250px;

  -webkit-box-shadow: 8px 8px 8px -5px rgba(0,0,0,0.20);
  -moz-box-shadow: 8px 8px 8px -5px rgba(0,0,0,0.20);
  box-shadow: 8px 8px 8px -5px rgba(0,0,0,0.20);
  box-sizing: border-box;
}

.signupBtn {
  outline: none;
  text-align: center;
  background: url(./overlay.png) repeat-x center #4D4E4F;
  border: 0px;
  font-family: Arial, Helvetica, sans-serif;
  color: rgba(255,255,255,1.00);
  letter-spacing: 1px;
  text-shadow: 0;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  padding:13px 20px;
  margin: 10px;
  width: 250px;

  -webkit-box-shadow: 8px 8px 8px -5px rgba(0,0,0,0.20);
  -moz-box-shadow: 8px 8px 8px -5px rgba(0,0,0,0.20);
  box-shadow: 8px 8px 8px -5px rgba(0,0,0,0.20);
  box-sizing: border-box;
}

p.hrBreak {
  overflow: hidden;
  text-align: center;
}

p.hrBreak:before,
p.hrBreak:after {
  background-color: rgba(197,197,197,1.0);
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

p.hrBreak:before {
  right: 0.5em;
  margin-left: -50%;
}

p.hrBreak:after {
  left: 0.5em;
  margin-right: -50%;
}

@media (max-width: 767px) {
  .utech-header {
    font-size: 14px;
    display: none;
  }

  .header-cart {
    display: none;
  }

  .signup-group {
    margin-left: 0;
  }

  .auth-links {
    display: inline-block;
    position: relative;
  }
}
