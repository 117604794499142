.folding_option {
	background: url(../Images/fold-style2.jpg) no-repeat;
	display: block;
	vertical-align: top;
	margin-left: 18px;
    margin-bottom: 15px;
    margin-top: 5px;
}

.folding_half_fold {
	background-position: 0px top;
	width: 100px;
    height: 180px;
}

.folding_tri_fold {
	background-position: -240px 3px;
	width: 100px;
    height: 180px;
}

.folding_roll_fold {
	background-position: -464px -3px;
	width: 100px;
    height: 180px;
}

.folding_double_parallel_fold {
	background-position: -705px -14px;
	width: 100px;
    height: 180px;
}

.folding_z_fold_3_panels {
	background-position: 0px bottom;
	width: 100px;
    height: 200px;
}

.folding_z_fold_4_panels {
	background-position: -280px bottom;
	width: 110px;
    height: 195px;
}

.folding_z_fold_5_panels {
	background-position: -550px bottom;
	width: 110px;
    height: 200px;
    
}
