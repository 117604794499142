.quotation-container {
    box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
    border: 1px solid rgba(34,36,38,.15);
    border-radius: .28571429rem;
    padding-top: 20px;
}

.quotation-id-title {
    border-bottom: 1px solid rgba(34,36,38,.15);
    padding-bottom: 15px;
}

.pd-tp20 {
    padding-top: 20px;
}

.billing-border {
    border-left: 1px solid rgba(34,36,38,.15);
    border-right: 1px solid rgba(34,36,38,.15);
}

.border-none {
    border-top: none !important;
}

div.edit_quote_btn {
    text-align: right;
    margin-bottom: 20px;
}

div.quote-details-features {
    margin-top: -10px;
    margin-right: 5px;
    border-bottom: 1px solid rgba(34,36,38,.15);
}

.preview-quote {
    border: 1px solid rgba(34,36,38,.15);
    box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
}

button.refresh-button {
    background-color: #787878;
}

button.page-link {
    color:#787878;
}

li.page-item.active button.page-link {
    background-color: #787878;
    border-color: #787878;
}

.table-border{
    border: 1px solid #dee2e6;
}

.table-border-quote-details {
    word-break: break-word;
    border: 1px solid #dee2e6;
}