.round_corner_type_img {
    display: block;
    width: 130px;
    height: 94px;
    margin-bottom: 15px;
}

.round_corner_RC001 {
	background: url(../Images/RC001.jpg) 0px top;
}

.round_corner_RC002 {
	background: url(../Images/RC002.jpg) 0px top;
}

.round_corner_RC003 {
	background: url(../Images/RC003.jpg) 0px top;
}

.round_corner_RC004 {
	background: url(../Images/RC004.jpg) 0px top;
}

.round_corner_RC005 {
	background: url(../Images/RC005.jpg) 0px top;
}

.round_corner_RC006 {
	background: url(../Images/RC006.jpg) 0px top;
}

.round_corner_RC007 {
	background: url(../Images/RC007.jpg) 0px top;
}

.round_corner_RC008 {
	background: url(../Images/RC008.jpg) 0px top;
}

.round_corner_RC009 {
	background: url(../Images/RC009.jpg) 0px top;
}

.round_corner_RC010 {
	background: url(../Images/RC010.jpg) 0px top;
}

.round_corner_RC011 {
	background: url(../Images/RC011.jpg) 0px top;
}

.round_corner_RC012 {
	background: url(../Images/RC012.jpg) 0px top;
}

.round_corner_RC013 {
	background: url(../Images/RC013.jpg) 0px top;
}

.round_corner_RC014 {
	background: url(../Images/RC014.jpg) 0px top;
}

.round_corner_RC015 {
	background: url(../Images/RC015.jpg) 0px top;
}