.page-content h1,
.page-content h2 {
  color: red;
}

.page-content h1 {
  font-size: 28px;
}
.page-content h2 {
  margin-top: 25px;
  font-size: 25px;
}
.page-content h3 {
  font-size: 23px;
}
.page-content p,
.page-content Label {
  font-size: 15px;
}

.mandatory {
  color: red;
}

/* UTech process pages breadcrumbs */
.process-link {
  margin-top: 30px;
  margin-bottom: 30px;
}

.process-link a {
  display: inline-block;
  padding-right: 15px;
  padding-left: 15px;
  border-left: 1px solid black;
  text-decoration: none;
  color: black;
}

.process-link a:first-child {
  border-left: none;
}

.process-link a:hover {
  color: red;
  transition: all 0.3s ease;
}

.process-link a.selected {
  color: red;
}

/* notes below form input */
.form-notes {
  margin-top: 8px;
  margin-bottom: 0px;
  font-size: 12px !important;
}

body {
  font-family: 'Roboto', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto Condensed', sans-serif;
}
