.prod-specs-container {
	width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	align-content: space-around;
}

.prod-specs, .prod-specs:any-link {
	background: linear-gradient(0.125turn, #a7021f 60%, #fe1035);
    color: white;
    border-radius: 5px;
    padding: 1em 1em;
    margin: 0.25em 0.5em;
    flex-grow: 1;
    flex-basis: 20%;
    text-align: center;
    /* font-size: 2em; */
}