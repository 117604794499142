.invoice-container {
    margin: 2rem;
    padding: 2rem;
    border: 1px solid gray;
}

.print-prompt {
    display: flex;
    justify-content: flex-end;
}

.document-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 1.5rem;
}

.invoice-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;
}

.invoice-address {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;
}

.document-footer {
    display: none;
}
.billing-address, .shipping-address {
    /* border: 1px solid gray; */
    min-width: 35%;
    padding: 0.5rem 0;
}

.address-title, .address-name {
    font-weight: bold;
}

.address-line {
    line-height: 1.25em;
}

.address-phone, .address-email {
    font-style: italic;
}

#listing-table> thead > tr > th:first-child,
#listing-table> tbody > tr > td:first-child {
    width: 10%;
}

#listing-table> thead > tr > th:last-child,
#listing-table> tbody > tr > td:last-child {
    width: 15%;
    text-align: right;
}

#listing-table > tbody > tr:nth-last-child(3) > td:first-child,
#listing-table > tbody > tr:nth-last-child(2) > td:first-child,
#listing-table > tbody > tr:nth-last-child(1) > td:first-child {
    border: unset;
}

.item-configs {
    line-height: 1em;
    font-size: 0.75em;
}


@media print {
    .dimmed.dimmable>.ui.visible.dimmer, .ui.active.dimmer {
        height: auto;
        position: absolute;
    }

    .print-prompt {
        display: none;
    }

    .utech-header, .navbar-bg, footer {
        display: none;
    }

    .invoice-container {
        margin: 0;
        border: unset;
    }

    .document-footer {
        display: flex;
        justify-content: center;
        margin-top: 2rem;
    }
    
    /* START - To remove the bootstrap dialog window UI */
    .modal-dialog {
        max-width: unset;
        margin: 0;
    }

    .modal-content {
        border: unset;
    }

    .modal-footer {
        display: none;
    }
    /* END - To remove the bootstrap dialog window UI */
}