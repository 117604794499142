#size-bar-parameter {
    width: 521px;
    height: 269px;
    position: relative;
    background: url(../Images/size-bar.jpg) no-repeat;
}

#size-bar-height {
    position: absolute;
    top: 108px;
    left: 10px;
    font-size: 16px;
}

#size-bar-width {
    position: absolute;
    top: 234px;
    left: 265px;
    font-size: 16px;
}

#size-bar-size-1 {
    position: absolute;
    top: 20px;
    left: 45px;
    width: 30px;
    text-align: center;
    padding-top: 10px;
    font-size: 16px;
    height: 188px;
    border-right: 1px dotted #ccc;
}

#size-bar-size-2 {
    position: absolute;
    top: 20px;
    right: 21px;
    width: 425px;
    text-align: center;
    font-size: 16px;
    padding-top: 10px;
    height: 188px;
}