.orientation_img {
    display: block;
}

.orientation_Landscape {
	background: url(../Images/landscape.jpg) no-repeat;
	height: 107px;
    width: 167px;
}

.orientation_Portrait {
	background: url(../Images/portrait.jpg) no-repeat;
	height: 167px;
    width: 107px;
}
