#cover_side_1_img {
    background: url(../Images/1-side-printing-cover.jpg) no-repeat;
    background-size: contain;
    height: 198px;
    display: block;
    margin-top: 5px;
}

#cover_side_2_img {
    background: url(../Images/2-sides-printing-cover.jpg) no-repeat;
    background-size: contain;
    height: 198px;
    display: block;
    margin-top: 5px;
}